'use strict';

// Constructor
var HoverBox = function() {
    jQuery('.hover-box').click(function(event) {
        console.log(event.currentTarget);
        if (event.currentTarget.getAttribute('href') != '#') {
            return;
        }
        event.stopPropagation();
        return false;
    });
};

module.exports = HoverBox;
