// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

window.jQuery = window.$ = require('jquery');
$.ui = require('jquery-ui');
var bootstrap = require('bootstrap-sass');
var HoverBox = require('../_modules/hover-box/hover-box');

$(function() {
    new HoverBox();

    var opened = 3;
    var scrollPosition= 0;

    var getUrlParameter = function getUrlParameter(sParam) {
      var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
         }
      }
    };


    var subsection = getUrlParameter('subsection');
    if(subsection!=undefined){
    	opened = Number(subsection);
      scrollPosition = $( "#accordation div:nth-child("+(opened+1)+")").offset().top;
    }

   

    $( "#accordation" ).accordion({
      collapsible: true,
      active: opened,
      heightStyle  : "content",
      navigation   : true,
      activate: function(event, ui) {
        $(ui.oldHeader).find(".moreButton").text("MORE");
        $(ui.newHeader).find(".moreButton").text("LESS");
  	  }
    });

    if(subsection!=undefined){
      $( "#accordation" ).find(".ui-accordion-header[aria-selected=true]").find(".moreButton").text("LESS");
    }




    $('html, body').animate({
        scrollTop: $(window).scrollTop() + scrollPosition
    });


    


    
});